import { DeleteOutline, Search } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import ShareContent from "../share-content/share-content";
import { useDispatch } from "react-redux";
import {
  fillRecentQueriesData,
  filteredPublications,
  publicationsSearch,
  resetfiltersData,
  triggerDeleteQuery
} from "../../Store/publications-slice/publication-slice";

export const QueriesActions = ({ query }) => {
  const dispatch = useDispatch();

  // useEffect to save recent queries to redux toolkit state
  const fillRecentQueries = (query) => {
    if (Object.keys(query.filter_option).length) {
      dispatch(fillRecentQueriesData(query));
      dispatch(
        filteredPublications({
          ...query.filter_option,
          searchText: query.search_text,
          page: 1
        })
      );
    } else if (query.search_text) {
      dispatch(resetfiltersData());
      dispatch(publicationsSearch(query.search_text, 1));
    }
  };
  const handleDeleteQuery = (id) => {
    dispatch(triggerDeleteQuery(id));
  };

  return (
    <>
      <Tooltip title="delete">
        <IconButton onClick={() => handleDeleteQuery(query.id)} size="small">
          <DeleteOutline sx={{ color: "#d9d9d9" }} />
        </IconButton>
      </Tooltip>
      <ShareContent query={query} />
      <Tooltip title="fill search queries">
        <IconButton onClick={() => fillRecentQueries(query)} size="small">
          <Search sx={{ color: "#d9d9d9" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};
