// Users Service hub to controll all the third party APIs related to users

import { toast } from "react-toastify";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// -------------------[ function to handle get current user details API call ]-------------------
export async function getCurrentUserDetails() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/user/profile`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      resolve(response.data.data);
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

// -------------------[ function to handle get all users list API call ]-------------------
export async function fetchAllUsersLIst() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/user`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      resolve(response.data.data);
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

//  -------------------[ function to handle get notifications API call ]-------------------
export async function fetchNotifications() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/notification`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      resolve(response.data.data);
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

// -------------------[ function to handle share search queries API call ]-------------------
export async function callReadNotifications(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/notification/status`;
      const response = await axios.post(
        apiUrl,
        { id },
        {
          headers: {
            "x-access-token": token
          }
        }
      );
      toast.success(response.data.data);
      resolve({
        data: response
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}
