import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import RecentQueries from "../../components/recent-queries/recent-queries";
import { useSelector } from "react-redux";
import FiltersMenu from "./filters/filters-menu";

const DashboardHeader = () => {
  const { searchQuery, searchLoading, totalPublications, publicationsData } =
    useSelector((state) => state.publicationsSlice);

  return (
    <Box className="dashboard-content-header">
      <Box>
        {searchLoading ? (
          <Typography>Loading...</Typography>
        ) : totalPublications > 0 ? (
          <Typography>
            1 to {publicationsData?.length} of {totalPublications} Publications
          </Typography>
        ) : totalPublications <= 0 && searchQuery ? (
          <Typography>No Result to Show</Typography>
        ) : (
          <Typography>Search for publications</Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <RecentQueries />
        <FiltersMenu />
      </Box>
    </Box>
  );
};

export default DashboardHeader;
