// This file contains current user details UI.

import { AccountCircle } from "@mui/icons-material";
import "./user-profile.css";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../Store/users-slice/users-slice";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { currentUser, getuserError, getUserLoading } = useSelector(
    (state) => state.usersSlice
  );

  // useEffect to dispatch get current details action
  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX Section
  return (
    <Box className="user-profile-form-container slide-up">
      {/* Current User Data */}
      <Box className="profile-form">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-76px"
          }}
        >
          {currentUser.photo_url ? (
            <Avatar
              src={currentUser.photo_url}
              sx={{ width: 80, height: 80, marginBottom: "10px" }}
              alt="Profile Image"
            />
          ) : (
            <AccountCircle
              sx={{
                color: "#fff",
                fontSize: "80px",
                margin: "auto",
                marginBottom: "10px"
              }}
            />
          )}
        </Box>
        <h1 style={{ color: "#95e6f7" }}>Profile</h1>
        {getUserLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : getuserError ? (
          <Typography sx={{ color: "red", textAlign: "center" }}>
            {getuserError}
          </Typography>
        ) : (
          <Box className="user-details">
            {currentUser?.first_name && currentUser?.last_name && (
              <>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    textTransform: "uppercase"
                  }}
                >
                  Name
                </p>
                <p style={{ color: "#95e6f7", fontSize: "16px" }}>
                  {`${currentUser.first_name} ${currentUser.last_name}`}
                </p>
              </>
            )}
            {currentUser?.email && (
              <>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    textTransform: "uppercase"
                  }}
                >
                  Email
                </p>
                <p style={{ color: "#95e6f7", fontSize: "16px" }}>
                  {currentUser.email}
                </p>
              </>
            )}
            {currentUser?.contact_number && (
              <>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    textTransform: "uppercase"
                  }}
                >
                  Phone
                </p>
                <p style={{ color: "#95e6f7", fontSize: "16px" }}>
                  {currentUser.contact_number}
                </p>
              </>
            )}
          </Box>
        )}
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default UserProfile;
