import React from "react";
import "./dashboard.css";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { SearchResultContent } from "./search-result-content";
import DashboardHeader from "./dashboard-header";
import { PublicationsRightPanel } from "./publications-right-panel.jsx/publications-right-panel";

const Dashboard = () => {
  return (
    <Box className="scroll-box" id="scrollBox">
      <Box
        sx={{
          color: "#fff",
          position: "relative"
        }}
      >
        <DashboardHeader />
        <Box className="dashboard-grid">
          <SearchResultContent />
          <PublicationsRightPanel />
        </Box>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Dashboard;
