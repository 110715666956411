// This component monitors the password condition to check that, required format is completed or not

import { CheckCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const PasswordValidator = ({ data }) => {
  const [fullLength, setFullLength] = useState(false);
  const [uppercaseLetter, setUppercaseLetter] = useState(false);
  const [lowerCaseLetter, setLowercaseLetter] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [number, setNumber] = useState(false);

  //   This useEffect monitor continuously to check the required conditions
  useEffect(() => {
    setFullLength(data?.password?.length >= 12);
    setUppercaseLetter(/[A-Z]/.test(data.password));
    setLowercaseLetter(/[a-z]/.test(data.password));
    setSpecialCharacter(/[!@#$%^&*()_+{}\\[\]:;<>,.?~\\-]/.test(data.password));
    setNumber(/[0-9]/.test(data.password));
    if (data.password) setPasswordMatch(data.password === data.confirmPassword);
  }, [data]);

  const validations = [
    {
      text: "Password should be at least 12 characters long, 14 or more is better.",
      condition: fullLength
    },
    {
      text: "At least 1 Uppercase letter.",
      condition: uppercaseLetter
    },
    {
      text: "At least 1 Lowercase letter.",
      condition: lowerCaseLetter
    },
    {
      text: "At least 1 special Character.",
      condition: specialCharacter
    },
    {
      text: "At least 1 number.",
      condition: number
    },
    {
      text: "Passwords should be matched.",
      condition: passwordMatch
    }
  ];

  return (
    <Box>
      {validations.map((el, i) => (
        <Box
          key={i}
          sx={{
            color: `${el.condition ? `#0cb952` : `#dadada`}`,
            display: "flex",
            alignItems: "start"
          }}
        >
          <CheckCircle
            sx={{ fontSize: "18px", marginRight: "15px", marginTop: "3px" }}
          />
          <Typography>{el.text}</Typography>
        </Box>
      ))}
    </Box>
  );
};
export default PasswordValidator;
