import * as React from "react";
import LoginForm from "../components/authentication/login-form";
import SignupForm from "../components/authentication/signup-form";
import ForgotPasswordView from "../components/authentication/forgot-password-form";
import OTPFormView from "../pages/authentications/otp-form.view";

import DefaultLayout from "../components/layouts/default-layout";
import Dashboard from "../pages/dashboard/dashboard.view";
import UserProfileView from "../pages/users/user-profile.view";
import EditUserProfileView from "../pages/users/edit-user-profile.view";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ChangePasswordView from "../pages/users/change-password.view";
import ResetPasswordView from "../pages/authentications/reset-password.view";

function Routes() {
  // Routes to controle pages navigation
  const routes = createBrowserRouter([
    {
      element: <DefaultLayout />,
      children: [
        {
          path: "/",
          element: <Dashboard />
        },
        {
          path: "/profile",
          element: <UserProfileView />
        },
        {
          path: "/profile/edit",
          element: <EditUserProfileView />
        },
        {
          path: "/change-password",
          element: <ChangePasswordView />
        }
      ]
    },
    {
      path: "/signup",
      element: <SignupForm />
    },
    {
      path: "/login",
      element: <LoginForm />
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordView />
    },
    {
      path: "/forgot-password/otp",
      element: <OTPFormView />
    },
    {
      path: "/reset-password",
      element: <ResetPasswordView />
    }
  ]);

  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default Routes;
