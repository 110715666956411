import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { Lock, ManageAccounts, Person, PersonOff } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../Store/users-slice/users-slice";

const UserprofileMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, getUserLoading } = useSelector(
    (state) => state.usersSlice
  );

  // useEffect to dispatch get current details action
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  // Function to handle account logout
  const handleLogout = () => {
    googleLogout();

    localStorage.clear("token");
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle menu close
  const handleClose = () => {
    setAnchorEl(null);
  };

  const avatarMake = ({ first_name, last_name }) => {
    if (getUserLoading) return <CircularProgress sx={{ padding: "10px" }} />;
    else if (first_name && last_name)
      return (first_name[0] + last_name[0]).toUpperCase();
    else return <PersonOff sx={{ color: "#606060" }} />;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                background: "#fff",
                color: "#000"
              }}
            >
              {currentUser?.photo_url?.length ? (
                <img
                  style={{ width: "100%" }}
                  src={currentUser?.photo_url}
                  alt="Avatar"
                />
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center"
                  }}
                >
                  {avatarMake(currentUser)}
                </Typography>
              )}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "200px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: "-10px",
            ml: "48px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem component={Link} to="/profile/edit">
          <ListItemIcon>
            <ManageAccounts fontSize="small" />
          </ListItemIcon>
          Profile Setting
        </MenuItem>
        <MenuItem component={Link} to="/change-password">
          <ListItemIcon>
            <Lock fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserprofileMenu;
