import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ArticleTextFormat from "./article-text-format";
import { Save, SaveAlt } from "@mui/icons-material";
import { downloadArticle, saveArticle } from "../../services/services";
import { Link } from "react-router-dom";
const AuthersFormat = ({ author, serial, length }) => {
  return (
    <Box sx={{ color: "#5ecee6", fontSize: "15px", position: "relative" }}>
      {author}
      {serial < length && <>,</>}
      <Typography
        sx={{
          fontSize: "8px",
          position: "absolute",
          right: "-2px",
          top: "-2px"
        }}
      >
        {serial}
      </Typography>
    </Box>
  );
};

const ArticleDetails = ({ publication }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const HandleDownloadArticle = async (id) => {
    try {
      setDownloadLoading(true);
      await downloadArticle(id);
    } catch (error) {
    } finally {
      setDownloadLoading(false);
    }
  };

  const HandleSaveArticle = async (id) => {
    try {
      setSaveLoading(true);
      await saveArticle(id);
    } catch (error) {
    } finally {
      setSaveLoading(false);
    }
  };

  const authors = ["John", "Smith", "David", "Lewis"];
  return (
    <Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Typography sx={{ fontSize: "15px", lineHeight: "25px" }}></Typography>
        <Box sx={{ display: "grid", gap: "10px" }}>
          {publication.title && (
            <Typography sx={{ color: "#5ecee6", fontSize: "18px" }}>
              {publication.title}
            </Typography>
          )}
          {publication.summarized_full_text && (
            <ArticleTextFormat
              heading="Summary"
              text={publication.summarized_full_text}
            />
          )}
          {publication.abstract && (
            <ArticleTextFormat heading="Abstract" text={publication.abstract} />
          )}
          {publication.publication_text && (
            <ArticleTextFormat
              heading="Publication Text"
              text={publication.publication_text}
            />
          )}
          {publication.source_search_criteria && (
            <ArticleTextFormat
              heading="Source"
              text={publication.source_search_criteria}
            />
          )}
          <Box sx={{ display: "flex", gap: "10px" }}>
            {authors.map((author, index) => (
              <Box key={index}>
                <AuthersFormat
                  author={author}
                  serial={index + 1}
                  length={authors?.length}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap"
            }}
          >
            <Box>
              <Typography
                component={Link}
                to={publication.link}
                target="blank"
                sx={{
                  fontSize: "13px",
                  color: "#5ecee6",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
              >
                See the patent from this publication
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px"
              }}
            >
              <Button
                sx={{
                  color: "#bdbec1",
                  fontSize: "13px",
                  fontWeight: "normal"
                }}
                onClick={() => HandleSaveArticle(publication.id)}
                size="small"
                startIcon={
                  saveLoading ? (
                    <CircularProgress style={{ width: 18, height: 18 }} />
                  ) : (
                    <Save />
                  )
                }
              >
                Save full article
              </Button>
              <Button
                sx={{
                  color: "#bdbec1",
                  fontSize: "13px",
                  fontWeight: "normal"
                }}
                onClick={() => HandleDownloadArticle(publication.id)}
                size="small"
                startIcon={
                  downloadLoading ? (
                    <CircularProgress style={{ width: 18, height: 18 }} />
                  ) : (
                    <SaveAlt />
                  )
                }
              >
                Download full article
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleDetails;
