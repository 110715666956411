import "./layout-provider.css";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";

export const LayoutProvider = ({ child }) => {
  return (
    <Box className="layout-provider-scroll-box">
      <Box className="scroll-content">{child || <Outlet />}</Box>
    </Box>
  );
};
