import React, { useEffect, useState } from "react";
import "./share-content.css";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from "@mui/material";
import { shareQuery } from "../../services/services";
import { Search, ShareOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomizedDialogs from "../custom-dialog/custom-dialog";

const ShareContent = ({ query }) => {
  const { usersList, usersListLoading, getUsersListError } = useSelector(
    (state) => state.usersSlice
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [shareLoading, setShareLoading] = useState(false);

  useEffect(() => {
    setUsers(usersList);
  }, [usersList]);

  const handleShareQuery = async () => {
    try {
      setShareLoading(true);
      await shareQuery({
        queryId: query.id,
        userList: selectedUsers
      });
    } catch (error) {
    } finally {
      setShareLoading(false);
    }
  };

  const handleCheck = (id) => {
    if (selectedUsers.includes(id)) {
      const updatedUsers = selectedUsers.filter((userId) => userId !== id);
      setSelectedUsers(updatedUsers);
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const avatarMake = ({ first_name, last_name }) => {
    if (first_name && last_name)
      return (first_name[0] + last_name[0]).toUpperCase();
    else return "";
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setUsers(
      usersList.filter((person) => {
        const fullName =
          `${person.first_name} ${person.last_name}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      })
    );
  };
  return (
    <CustomizedDialogs
      sx={{ minHeight: "90vh" }}
      loading={shareLoading}
      title="Share search query"
      tooltipTitle="share"
      openIcon={<ShareOutlined />}
      actions={
        <Button
          onClick={handleShareQuery}
          sx={{ fontWeight: "normal", color: "#d9d9d9" }}
          startIcon={<ShareOutlined />}
        >
          Share Now
        </Button>
      }
      children={
        <Box className="share-content-container">
          <Box>
            <OutlinedInput
              onChange={handleSearchChange}
              placeholder="write user name to find"
              autoComplete="off"
              size="small"
              sx={{
                color: "#d9d9d9",
                width: "100%",
                ".css-i4bv87-MuiSvgIcon-root": {
                  color: "#808080"
                }
              }}
              id="outlined-adornment-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">{<Search />}</IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box className="share-content-inner">
            <Box className="share-content-box">
              <Box className="users-list">
                {usersListLoading ? (
                  <CircularProgress />
                ) : getUsersListError ? (
                  <Typography
                    sx={{
                      textAlign: "center",
                      padding: "20px 10px",
                      color: "#d51212"
                    }}
                  >
                    {getUsersListError}
                  </Typography>
                ) : (
                  <>
                    {users.map((user, i) => (
                      <Box
                        key={i}
                        onClick={() => handleCheck(user.id)}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          padding: "5px 8px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          transition: "all 0.2s ease-in-out",
                          "&:hover": { background: "#090034" }
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            color: "#000"
                          }}
                        >
                          {user?.photo_url?.length ? (
                            <img
                              style={{ width: "100%" }}
                              src={user?.photo_url}
                              alt="Avatar"
                            />
                          ) : (
                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "cneter",
                                alignItems: "center"
                              }}
                            >
                              {avatarMake(user)}
                            </Typography>
                          )}
                        </Avatar>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "14px",
                              color: "#d9d9d9"
                            }}
                          >
                            {`${user.first_name} ${user.last_name}`}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: " 12px",
                              color: "#d9d9d9",
                              lineHeight: "14px"
                            }}
                          >
                            {user.email}
                          </Typography>
                        </Box>
                        <Checkbox
                          sx={{ color: "#d9d9d9" }}
                          checked={selectedUsers.includes(user.id)}
                        />
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default ShareContent;
