import React from "react";
import "./confirm-alert.css";
import { SaveOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

export const ConfirmAlert = ({ open, close, confirm }) => {
  return (
    <Box
      className="alert-box"
      style={
        open
          ? { opacity: 1, PointerEvent: "all" }
          : { opacity: 0, pointerEvents: "none" }
      }
    >
      <Box onClick={close} className="alert-bg"></Box>
      <Box className="alert-content">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontSize: "15px",
            color: "#d9d9d9"
          }}
        >
          <SaveOutlined />
          Do you wish to save this query?
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px"
          }}
        >
          <Button
            onClick={confirm}
            variant="outlined"
            size="small"
            sx={{ color: "#fff" }}
          >
            Yes
          </Button>
          <Button
            onClick={close}
            variant="outlined"
            size="small"
            sx={{ color: "#d9d9d9" }}
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmAlert;
