import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

const theme = createTheme({
  // Here are all the external link with theme
  palette,
  typography,
  components: {
    // Custom styling to override existing styles of MUI components
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#fff",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "35px",
            color: "#fff",
            border: "1px solid #808080",
            padding: "8px 1rem",
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            border: "1px solid #808080",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            border: "1px solid #808080",
            color: "#fff",
          },
        },
        MuiFormLabel: {
          MuiFormLabel: {
            color: "#fff",
          },
          root: {
            color: "#fff",
          },
        },
      },
    },
  },
});

export default theme;
