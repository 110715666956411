// This file deals with user login

import React, { useState, useEffect } from "react";
import "./authentication.css";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import {
  login,
  loginWithGoogle
  // loginWithFacebook
} from "../../services/services";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
// import { Button } from "@mui/base";
// import { Facebook } from "@mui/icons-material";

const LoginForm = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [captcha, setCaptcha] = useState("");
  const [captchaEmpty, setCaptchaEmpty] = useState(false);
  // const [facebookBtnDisabled, setFacebookBtnDisabled] = useState(false);
  const [loginLoading, setLogInLoading] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
  });

  // Handling change in input fields to store data in formData
  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  // function to handle login API call function
  const handleLogin = async (event) => {
    for (const key in formData) {
      if (!formData[key]) return;
    }
    event.preventDefault();
    if (!captcha) {
      setCaptchaEmpty(true);
      return;
    }
    try {
      setLogInLoading(true);
      await login(formData);
      navigate("/login");
    } catch (error) {
    } finally {
      setLogInLoading(false);
    }
  };

  // Handling check of reCaptcha
  function onChange(value) {
    setCaptcha(value);
    if (value) setCaptchaEmpty(false);
  }

  // Handling login with google
  const handleSuccess = async (response) => {
    try {
      await loginWithGoogle(response.credential);
      navigate("/");
    } catch (error) {}
  };
  const handleFail = () => {};

  // Below portion deals with login with Facebook
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      xfbml: true,
      version: "v18.0"
    });
    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  // const facebookLogin = async (data) => {
  //   try {
  //     setFacebookBtnDisabled(true);
  //     await loginWithFacebook(data);
  //     navigate("/");
  //   } catch (error) {
  //   } finally {
  //     setFacebookBtnDisabled(false);
  //   }
  // };

  // const responseFacebook = () => {
  //   window.FB.login(function (response) {
  //     if (response.authResponse) {
  //       const token = response.authResponse.accessToken;
  //       const userId = response.authResponse.userID;
  //       facebookLogin({ accessToken: token, userId: userId });
  //     } else {
  //       setFacebookBtnDisabled(false);
  //       toast.error("Facebook login fail!");
  //     }
  //   });
  // };

  // JSX Section below
  return (
    <Box
      className="auth-form-container"
      sx={{
        background: theme.palette.background.background
      }}
    >
      {/* Login form fields */}
      <form className="auth-form">
        <h1 style={{ color: "#95e6f7" }}>Login Here</h1>
        <div className="inputs">
          <BaseInput
            required
            label="Email"
            type="email"
            name="email"
            onChange={(values) => handleChange(values)}
          />
          <BaseInput
            required
            label="Password"
            type="password"
            name="password"
            onChange={(values) => handleChange(values)}
          />

          <Box>
            {/* --------------------[ ReCAPTCHA to prevent spams ]-------------------- */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}> 
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onChange}
              />
            </Box>
            {captchaEmpty && (
              <Typography sx={{ color: "#ff0000", fontSize: "13px" }}>
                Please verify, your are not robot
              </Typography>
            )}
          </Box>

          <BaseButton
            onClick={handleLogin}
            loading={loginLoading}
            type="submit"
            label="Log in"
          ></BaseButton>
          <Typography sx={{ color: "#fff", textAlign: "center" }}>
            or login with
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              margin: "auto"
            }}
          >
            {/* --------------------[ Login in with google button ]-------------------- */}
            <GoogleLogin
              onSuccess={(response) => handleSuccess(response)}
              onError={handleFail}
            />
            {/* --------------------[ Login in with Facebook button ]-------------------- */}
            {/* <Button
              className="facebook-button"
              style={
                facebookBtnDisabled
                  ? { opacity: "0.6", cursor: "not-allowed" }
                  : {}
              }
              disabled={facebookBtnDisabled}
              onClick={responseFacebook}
            >
              <Facebook sx={{ color: "#216df7", fontSize: "30px" }} />
              <Typography
                sx={{ flexGrow: "1", fontSize: "14px", color: "#7d7d7d" }}
              >
                Login with facebook
              </Typography>
            </Button> */}
          </Box>

          <Link
            to="/forgot-password"
            className="navigation-link"
            style={{ marginTop: "5px" }}
          >
            <Typography>Forgot Password</Typography>
          </Link>

          <Box sx={{ color: "#FFF", display: "flex" }}>
             {/* <Typography>Don't have an account?</Typography>
            <Link to="/signup" className="navigation-link">
              <Typography sx={{ paddingLeft: "10px" }}>Signup here</Typography>
            </Link> */}
          </Box>
        </div>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default LoginForm;
