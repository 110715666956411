import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect
} from "react";
import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import { Avatar, Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AvatarEditor from "react-avatar-editor";
import { updateCurrentUserProfile } from "../services/services";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../Store/users-slice/users-slice";

const ProfileImageInput = forwardRef(({ url, updateFile }, ref) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [scale, setScale] = useState(1);
  const editorRef = useRef("");
  const fileInputRef = useRef(null);

  // Function to handle image input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // Function to handle image cropping and resizing
  const handleScaleChange = (e) => {
    const scaleValue = parseFloat(e.target.value);
    setScale(scaleValue);
  };

  // Function to handle final result image after cropping and resizing
  const handleImageReady = async () => {
    if (!editorRef.current) return;
    const originalFileName = editorRef.current.props.image.name;
    const originalFileType = editorRef.current.props.image.type;
    const canvas = editorRef?.current?.getImageScaledToCanvas().toDataURL();
    const blob = dataURItoBlob(canvas);
    const file = new File([blob], originalFileName, { type: originalFileType });
    const formData = new FormData();
    formData.append("image", file);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await updateCurrentUserProfile(formData);
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        dispatch(getCurrentUser());
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to convert image data to blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/png" });
  };

  // Function to emit event to parent component so that we can call
  // handleImageReady function in parent with update user profile data
  useImperativeHandle(ref, () => ({
    getImage() {
      if (selectedFile) {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await handleImageReady();
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      }
    }
  }));

  // useEffect to handle file change to handle update button disabled
  useEffect(() => {
    if (selectedFile) updateFile(true);
    else updateFile(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  return (
    <Box sx={{ marginBottom: "20px" }}>
      {selectedFile || url ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px"
            }}
          >
            {selectedFile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <AvatarEditor
                  ref={editorRef}
                  width={100}
                  height={100}
                  image={selectedFile}
                  border={5}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  borderRadius={50}
                />
                <Slider
                  size="small"
                  defaultValue={0}
                  step={0.1}
                  min={1}
                  max={5}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                  value={scale}
                  onChange={handleScaleChange}
                  sx={{ width: "110px" }}
                />
              </Box>
            ) : (
              <Avatar
                src={url}
                sx={{ width: 100, height: 100, marginBottom: "10px" }}
                alt="Profile Image"
              />
            )}
          </Box>
          <input
            id="image-input"
            style={{ display: "none" }}
            type="file"
            onChange={handleFileChange}
            accept="image/*"
          />
          <label
            htmlFor="image-input"
            ref={fileInputRef}
            style={{
              color: "#fff",
              fontSize: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <AddPhotoAlternateOutlined />
            <Typography>Change Image</Typography>
          </label>
        </>
      ) : (
        <>
          <label htmlFor="image-input">
            <AddPhotoAlternateOutlined
              sx={{ color: "#858585", fontSize: "80px", cursor: "pointer" }}
            />
          </label>
          <input
            id="image-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="image/*"
          />
        </>
      )}
    </Box>
  );
});

export default ProfileImageInput;
