import React from "react";
import UserProfile from "../../components/users/user-profile";
import { Box } from "@mui/system";
import { LayoutProvider } from "../../components/layouts/layout-provider";

const UserProfileView = () => {
  return (
    <LayoutProvider
      child={
        <Box
          sx={{
            padding: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <UserProfile />;
        </Box>
      }
    />
  );
};

export default UserProfileView;
