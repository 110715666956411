import "./header.css";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import { toggleSidebar } from "../../Store/styles-slice/styles-slice";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, OutlinedInput, Typography } from "@mui/material";
import {
  getRecentQueries,
  publicationsSearch,
  resetfiltersData
} from "../../Store/publications-slice/publication-slice";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderOptionsMenu from "./header-options-menu";
import { useLocation, useNavigate } from "react-router";
import {
  downloadAllSectedArticle,
  saveSearchQuery
} from "../../services/services";
import ArticleHandlingButton from "./article-handling-button";
import ConfirmAlert from "../confirm-alert/confirm-alert";
import Notifications from "../notifications/notifications";

const Header = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { searchQuery, selectedPublications, filtersData } = useSelector(
    (state) => state.publicationsSlice
  );

  const [searchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);

  useEffect(() => {
    setSearchText(searchQuery);
  }, [searchQuery]);

  // this will handle change to set search text into state
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  // Function to search publications according to search query
  const handleSearchPublications = (event) => {
    if (location.pathname !== "/") navigate("/");
    event.preventDefault();
    if (!searchText) return;
    dispatch(resetfiltersData());
    dispatch(publicationsSearch(searchText, 1));
  };

  // function to handle download all selected articles
  const handleExportArticles = async () => {
    if (!selectedPublications?.length) return;
    try {
      setExportLoading(true);
      await downloadAllSectedArticle(selectedPublications);
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  // function to handle download all selected articles
  const handleSaveQueries = async () => {
    setSaveConfirm(false);
    if (!searchQuery && !Object.keys(filtersData).length) {
      return;
    }
    try {
      setSaveLoading(true);
      await saveSearchQuery({
        searchText: searchQuery,
        filterOptions: filtersData
      });
      dispatch(getRecentQueries());
    } catch (error) {
    } finally {
      setSaveLoading(false);
    }
  };

  const handleSaveAlert = () => {
    if (!searchText && !Object.keys(filtersData).length) return;
    setSaveConfirm(true);
  };

  return (
    <>
      <Box
        className="header-container"
        sx={{
          width: "100%",
          background: theme.palette.background.background2,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
          transition: "all 0.4s"
        }}
      >
        <Box className="header-menu">
          <IconButton
            onClick={() => dispatch(toggleSidebar())}
            className="bar-btn"
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Box
            sx={{
              flexGrow: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <form className="search-form" onSubmit={handleSearchPublications}>
              <OutlinedInput
                value={searchText}
                onChange={handleChange}
                className="search-input"
                placeholder="Search"
                type="search"
                size="small"
                startAdornment={
                  <Search
                    position="start"
                    sx={{ marginRight: "5px", color: "#b7b7b7" }}
                  />
                }
                sx={{ padding: "0px 10px" }}
              />
            </form>
          </Box>

          <Box className="default-menu" sx={{ display: "flex", gap: "10px" }}>
            <ArticleHandlingButton
              onClick={handleSaveAlert}
              loading={saveLoading}
              label="Save"
            />

            <ArticleHandlingButton
              onClick={handleExportArticles}
              loading={exportLoading}
              label="Export"
            />
          </Box>
          <Notifications />
        </Box>
        {/* --------- Alert before save query --------- */}
        <ConfirmAlert
          open={saveConfirm}
          confirm={handleSaveQueries}
          close={() => setSaveConfirm(false)}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography sx={{ color: "#fff", height: "20px" }}>
            {searchQuery}
          </Typography>
          <Box className="optional-menu">
            <HeaderOptionsMenu
              handleSaveAlert={handleSaveAlert}
              handleExportArticles={handleExportArticles}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
