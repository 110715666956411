import * as React from "react";
import "./custom-dialog.css";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { CircularProgress, Tooltip, Typography } from "@mui/material";

const CustomizedDialogs = ({
  children,
  actions,
  openIcon,
  title,
  tooltipTitle,
  loading,
  backgroundVisible,
  sx
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box variant="outlined" onClick={handleClickOpen}>
        <Tooltip title={tooltipTitle}>
          <IconButton sx={{ fontWeight: "normal", color: "#d9d9d9" }}>
            {openIcon}
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          opacity: open ? "1" : "0",
          display: open ? "block" : "none"
        }}
      >
        {backgroundVisible && (
          <Box
            onClick={handleClose}
            className="dialogue-background"
            sx={{ display: open ? "block" : "none" }}
          ></Box>
        )}
        <Box className="custom-dialogue-inner">
          <Box sx={{ borderBottom: "1px solid #545454 " }}>
            <Typography
              sx={{ padding: "20px 25px", color: "#fff" }}
              id="customized-dialog-title"
            >
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="dialogue-content">{children}</Box>
          {actions && (
            <DialogActions sx={{ borderTop: "1px solid #545454 " }}>
              {loading ? (
                <CircularProgress size={30} sx={{ margin: "5px 30px" }} />
              ) : (
                <>{actions}</>
              )}
            </DialogActions>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizedDialogs;
