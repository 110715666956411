// This file deals with forgot password case

import React, { useState, useEffect } from "react";
import "./authentication.css";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { forgetPassword } from "../../services/services";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
  });

  // Handling change in input fields to store data in formData
  const handleChange = (value) => {
    setEmail(value);
  };

  // function to handle forget password API call function
  const handleLResetPassword = async (event) => {
    if (!email) return;
    event.preventDefault();
    try {
      setForgotPassword(true);
      await forgetPassword(email);
      navigate("/forgot-password/otp");
    } catch (error) {
    } finally {
      setForgotPassword(false);
    }
  };

  // JSX Section
  return (
    <Box
      className="auth-form-container"
      sx={{
        background: theme.palette.background.background
      }}
    >
      {/* forgot password form fields */}
      <form className="auth-form">
        <h1 style={{ color: "#95e6f7" }}>Reset Password</h1>
        <div className="inputs">
          <BaseInput
            required
            name="email"
            onChange={(values) => handleChange(values.value)}
            label="Email"
          />
          <BaseButton
            onClick={handleLResetPassword}
            loading={forgotPassword}
            type="submit"
            label="Reset"
          ></BaseButton>

          <Link
            to="/login"
            className="navigation-link"
            style={{ margin: "auto", width: "fit-content" }}
          >
            <Typography>Back to Login</Typography>
          </Link>
        </div>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default ForgotPasswordForm;
