import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

const ArticleTextFormat = ({ heading, text }) => {
  const [resultText, setResultText] = useState(text);
  const [textExpended, setTextExpended] = useState(false);

  const maxText = 240;

  useEffect(() => {
    if (text?.length > maxText) setResultText(resultText?.slice(0, maxText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const handleMore = () => {
    setTextExpended(true);
    setResultText(text);
  };
  const handleLess = () => {
    setResultText(resultText.slice(0, maxText));
    setTextExpended(false);
  };

  const buttonStyles = {
    color: "#4b1cf4",
    cursor: "pointer"
  };
  const headingStyles = {
    fontSize: "18px",
    color: "#fff",
    marginRight: "10px"
  };

  return (
    <Box>
      <Typography sx={{ color: "#b7b7b7", fontSize: "15px" }}>
        <span style={headingStyles}>{heading}:</span>
        <span style={{ wordWrap: "break-word" }}>{resultText}</span>
      </Typography>
      {text?.length > maxText && (
        <>
          {textExpended ? (
            <span onClick={handleLess} style={buttonStyles}>
              show Less
            </span>
          ) : (
            <span onClick={handleMore} style={buttonStyles}>
              show More
            </span>
          )}
        </>
      )}
    </Box>
  );
};

export default ArticleTextFormat;
