// usepublications.js

import { createSlice } from "@reduxjs/toolkit";
import {
  searchPublications,
  getFilteredPublications,
  fetchRecentQueries,
  deleteQuery
} from "../../services/services";

export const usepublications = createSlice({
  name: "publications",
  initialState: {
    publicationsData: [],
    selectedPublications: [],
    filtersData: {},
    selectedPublication: [],
    recentQueries: [],
    selectedQuery: {},
    totalPublications: 0,
    searchQuery: "",
    getPublicationsError: "",
    getRecentQueriesError: "",
    sidebarOpen: true,
    searchLoading: false,
    initialSearch: false,
    recentQueriesLoading: false,
    deleteQueryLoading: false,
    savePublication: []
  },
  reducers: {
    setPublicationsResult: (state, { payload }) => {
      state.getPublicationsError = "";

      // for only first time search, totalPublicationsRecord will be reset
      if (payload.page === 1) state.totalPublications = 0;

      // if payload contain searchQuery, then assign it to state
      if (payload.text) state.searchQuery = payload.text;

      // store filters data to use it later.
      if (payload.filtersData) {
        state.filtersData = payload.filtersData;
      }

      // if page number is 1, set inital search to true, Reset publicatoins data and assign new value to it
      if (payload.page === 1) {
        state.initialSearch = true;
        state.publicationsData = [];
        state.publicationsData = payload.data;
      } else if (payload.data) {
        // otherwise add new data to publications data, along side with previous data
        state.publicationsData = state.publicationsData.concat(payload.data);
        state.initialSearch = false;
      }

      // set total publications record to use it later
      if (payload.totalPublications)
        state.totalPublications = payload.totalPublications;

      // store if any error occure while searching
      if (payload.error) state.getPublicationsError = payload.error;
    },
    setSearchPublicationsLoading: (state, { payload }) => {
      state.searchLoading = payload;
    },
    setSelectedPublications: (state, { payload }) => {
      state.selectedPublications = payload;
    },
    resetFiltersData: (state, _) => {
      state.filtersData = {};
    },
    setRecentQueries: (state, { payload }) => {
      state.getRecentQueriesError = "";
      if (payload.data) state.recentQueries = payload.data;
      if (payload.error) state.getRecentQueriesError = payload.error;
    },
    // This will set queries data to filters window and search field in dashbaord
    setRecentQueriesData: (state, { payload }) => {
      state.selectedQuery = payload;
      state.filtersData = payload.filter_option;
      state.searchQuery = payload.search_text;
    },
    setRecentQueriesLoading: (state, { payload }) => {
      state.recentQueriesLoading = payload;
    },
    setDeletedQuery: (state, { payload }) => {
      state.recentQueries = state.recentQueries.filter(
        (el) => el.id !== payload
      );
    },
    setDeleteQueryLoading: (state, { payload }) => {
      state.deleteQueryLoading = payload;
    }
  }
});

export default usepublications.reducer;
export const {
  setSearchPublications,
  setSearchPublicationsLoading,
  setSelectedPublications,
  resetFiltersData,
  setRecentQueries,
  setRecentQueriesData,
  setRecentQueriesLoading,
  setSavedQueries,
  setDeletedQuery,
  setDeleteQueryLoading
} = usepublications.actions;
export const { setPublicationsResult } = usepublications.actions;

export const toggleSidebar = () => async (dispatch) => {
  dispatch(setSearchPublications());
};

export const storeSelectedPublications = (publications) => async (dispatch) => {
  dispatch(setSelectedPublications(publications));
};

export const publicationsSearch = (searchText, page) => async (dispatch) => {
  try {
    dispatch(setSelectedPublications([]));
    dispatch(setSearchPublicationsLoading(true));
    dispatch(
      setPublicationsResult({
        text: searchText,
        page: page
      })
    );

    const response = await searchPublications(searchText, page);
    dispatch(
      setPublicationsResult({
        data: response.data,
        page: page,
        totalPublications: response.totalPublications,
        error: response.error
      })
    );
  } catch (error) {
    dispatch(setPublicationsResult({ error: error }));
  } finally {
    dispatch(setSearchPublicationsLoading(false));
  }
};

export const resetfiltersData = () => async (dispatch) => {
  dispatch(resetFiltersData());
};

export const filteredPublications = (data) => async (dispatch) => {
  try {
    dispatch(setSearchPublicationsLoading(true));
    dispatch(
      setPublicationsResult({
        filtersData: data,
        page: data.page
      })
    );
    dispatch(setSelectedPublications([]));
    const response = await getFilteredPublications(data);
    // Dispatch an action to update the state with the received data
    dispatch(
      setPublicationsResult({
        data: response.data,
        page: data.page,
        totalPublications: response.totalPublications,
        error: response.error
      })
    );
  } catch (error) {
    // Dispatch an action to update the state with the received errpr
    dispatch(setPublicationsResult({ error: error }));
  } finally {
    dispatch(setSearchPublicationsLoading(false));
  }
};

export const getRecentQueries = () => async (dispatch) => {
  try {
    dispatch(setRecentQueriesLoading(true));
    const data = await fetchRecentQueries();
    dispatch(setRecentQueries({ data }));
  } catch (error) {
    dispatch(setRecentQueries({ error }));
  } finally {
    dispatch(setRecentQueriesLoading(false));
  }
};

export const fillRecentQueriesData = (query) => async (dispatch) => {
  dispatch(setRecentQueriesData(query));
};

export const triggerDeleteQuery = (id) => async (dispatch) => {
  try {
    dispatch(setDeleteQueryLoading(true));
    await deleteQuery(id);
    dispatch(setDeletedQuery(id));
  } catch (error) {
  } finally {
    dispatch(setDeleteQueryLoading(false));
  }
};
