import { ArrowUpward } from "@mui/icons-material";
import "./dashboard.css";
import "./search-result-content.css";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ArticleDetails from "./article-details";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredPublications,
  publicationsSearch,
  storeSelectedPublications
} from "../../Store/publications-slice/publication-slice";

export const SearchResultContent = () => {
  const {
    searchQuery,
    searchLoading,
    getPublicationsError,
    totalPublications,
    publicationsData,
    initialSearch,
    filtersData,
    selectedPublications
  } = useSelector((state) => state.publicationsSlice);

  const [pageNumber, setPageNumber] = useState(1);
  const [bottomReached, setBottomReached] = useState(false);
  const dispatch = useDispatch();

  const [selectedArticles, setSelectedArticles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [scrollIncreased, setScrollIncreased] = useState(false);

  // useEffect to set next page when it increases
  useEffect(() => {
    if (!searchLoading) {
      if (bottomReached) setPageNumber(pageNumber + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomReached]);

  useEffect(() => {
    setSelectedArticles(selectedPublications);
  }, [selectedPublications]);

  const getArticlesByPageNumber = () => {
    if (
      !searchLoading &&
      pageNumber > 1 &&
      publicationsData?.length < totalPublications &&
      searchQuery &&
      !Object.keys(filtersData).length
    ) {
      dispatch(publicationsSearch(searchQuery, pageNumber));
      setSelectedArticles([]);
    }
    if (Object.keys(filtersData).length) {
      setSelectedArticles([]);
      dispatch(
        filteredPublications({
          ...filtersData,
          searchText: searchQuery,
          page: pageNumber
        })
      );
    }
  };

  // useEffect to monitor the scroll, once scoll will reach the bottom, articles will be fetched
  useEffect(() => {
    getArticlesByPageNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  // Function to handle selection/deselection of all article at a time
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedArticles([]);
    } else {
      const allIds = publicationsData.map((publication) => publication.id);
      setSelectedArticles(allIds);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  // Function to handle selection of articles on by one
  const handleSelectArticles = (id) => {
    if (!selectedArticles.includes(id)) {
      setSelectedArticles((prevSelectedArticles) => [
        ...prevSelectedArticles,
        id
      ]);
    } else {
      setSelectedArticles((prevSelectedArticles) =>
        prevSelectedArticles.filter((el) => el !== id)
      );
    }
    setSelectAll(false);
  };

  // useEffect to dispatch store function to download Selected articles.
  useEffect(() => {
    dispatch(storeSelectedPublications(selectedArticles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticles]);

  // Function to monitor scroll position
  const handleScroll = () => {
    if (!searchLoading) {
      const box = document.getElementById("scrollBox");
      // set scroll increase to handle scroll to top button
      box.scrollTop > 200
        ? setScrollIncreased(true)
        : setScrollIncreased(false);
      // check bottom of scroll box is reached or not
      const isAtBottom =
        box.scrollHeight - box.scrollTop - box.clientHeight < 300;
      if (isAtBottom) {
        setBottomReached(isAtBottom);
      } else setBottomReached(isAtBottom);
    }
  };

  // Function to reset search state when user start new search by search field
  useEffect(() => {
    if (initialSearch) setPageNumber(1);
  }, [initialSearch]);

  // useEffect to trigger scroll functions
  useEffect(() => {
    const box = document.getElementById("scrollBox");
    box.addEventListener("scroll", handleScroll);
    return () => box.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle scroll to top
  const scrollToTop = () => {
    const box = document.getElementById("scrollBox");
    const startingY = box.scrollTop;
    const targetY = 0;
    const duration = 1000;

    const startTime = performance.now();

    const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;

      box.scrollTop =
        startingY +
        (targetY - startingY) * easeInOutQuad(elapsedTime / duration);

      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return (
    <Box className="search-content-container">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {publicationsData?.length > 1 && (
          <FormControlLabel
            label="Select All"
            sx={{ padding: "0px 10px" }}
            control={
              <Checkbox
                sx={{
                  color: "#b7b7b7",
                  marginRight: "10px",
                  padding: "0px"
                }}
                checked={selectAll}
                onChange={handleSelectAll}
              />
            }
          />
        )}
        {publicationsData?.map((publication, i) => (
          <Box
            key={i}
            className="slide-up"
            sx={{ display: "flex", alignItems: "start" }}
          >
            <Checkbox
              checked={selectedArticles.includes(publication.id)}
              onClick={() => handleSelectArticles(publication.id)}
              sx={{ color: "#b7b7b7", padding: "0px" }}
            />
            <ArticleDetails publication={publication} />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "10px 0px"
        }}
      >
        {searchLoading ? (
          <CircularProgress />
        ) : (
          <>
            {getPublicationsError && initialSearch && (
              <Box
                sx={{ display: "flex", alignItems: "center", margin: "auto" }}
              >
                <IconButton onClick={getArticlesByPageNumber}></IconButton>
                <Typography sx={{ color: "#af0000", textAlign: "center" }}>
                  {getPublicationsError}
                </Typography>
              </Box>
            )}
            {getPublicationsError && !initialSearch && (
              <Box
                sx={{ display: "flex", alignItems: "center", margin: "auto" }}
              >
                <IconButton onClick={getArticlesByPageNumber}></IconButton>
                <Typography sx={{ color: "#af0000", textAlign: "center" }}>
                  {getPublicationsError}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      <Button
        onClick={scrollToTop}
        variant="contained"
        sx={{
          padding: "5px",
          minWidth: "10px",
          backgroundColor: "#473df575",
          position: "fixed",
          bottom: "40px",
          right: "70px",
          transition: "all 0.2s ease-in-out"
        }}
        style={
          scrollIncreased
            ? { opacity: "1" }
            : { opacity: "0", PointerEvent: "none" }
        }
      >
        <ArrowUpward sx={{ color: "white" }} />
      </Button>
    </Box>
  );
};
