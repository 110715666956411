import React, { useEffect } from "react";
import "./recent-queries.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getRecentQueries } from "../../Store/publications-slice/publication-slice";
import { getAllUsersLIst } from "../../Store/users-slice/users-slice";
import { QueriesActions } from "./queries-actions";
import { dateMakerWithMonthName } from "../../helpers";
import CustomizedDialogs from "../custom-dialog/custom-dialog";
import { ManageSearch } from "@mui/icons-material";

// recent queries component --------------->
const RecentQueries = () => {
  const dispatch = useDispatch();

  const {
    recentQueries,
    getRecentQueriesError,
    recentQueriesLoading,
    deleteQueryLoading
  } = useSelector((state) => state.publicationsSlice);

  // useEffect to get users list by api call
  useEffect(() => {
    dispatch(getAllUsersLIst());
  }, [dispatch]);

  // useEffect to get recent queries by api call
  useEffect(() => {
    dispatch(getRecentQueries());
  }, [dispatch]);

  return (
    <CustomizedDialogs
      backgroundVisible={true}
      title="Search Queries"
      tooltipTitle="History"
      openIcon={<ManageSearch sx={{ color: "" }} />}
      children={
        <Box className="recent-queries-container">
          <Box className="paragraphs">
            <p>These are all queries saved in your profile. </p>
            <p>They are available every time you login </p>
          </Box>
          <>
            {recentQueriesLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  padding: "30px 10px"
                }}
              >
                <CircularProgress />
              </Box>
            ) : getRecentQueriesError ? (
              <Typography
                sx={{
                  textAlign: "center",
                  padding: "20px 10px",
                  color: "#d51212"
                }}
              >
                {getRecentQueriesError}
              </Typography>
            ) : recentQueries?.length === 0 ? (
              <Typography
                sx={{
                  textAlign: "center",
                  padding: "20px 10px",
                  color: "#d9d9d9"
                }}
              >
                No Recent History
              </Typography>
            ) : (
              <Box className="query-content-box">
                <Box className="heading">
                  <Box className="heading-item">Search Text</Box>
                  <Box className="heading-item">Filters Criteria</Box>
                  <Box className="heading-item actions">
                    {deleteQueryLoading && (
                      <CircularProgress sx={{ margin: "auto" }} size={15} />
                    )}
                  </Box>
                </Box>
                {recentQueries.map((query, i) => (
                  <Box key={i} className="content">
                    <Box className="content-item">{query.search_text}</Box>
                    <Box className="content-item">
                      {query.filter_option.filterCriteria && (
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "bold" }}
                          >
                            Filter by :
                          </Typography>
                          <Typography sx={{ fontSize: "13px" }}>
                            {query.filter_option.filterCriteria},
                          </Typography>
                        </Box>
                      )}
                      {query.filter_option.date ||
                        (query.filter_option.endDate && (
                          <>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: "bold"
                              }}
                            >
                              Date:
                            </Typography>
                            {query.filter_option.date && (
                              <Typography sx={{ fontSize: "13px" }}>
                                {dateMakerWithMonthName(
                                  query.filter_option.date
                                )}
                                <b style={{ color: "#fff" }}> to</b>
                              </Typography>
                            )}
                          </>
                        ))}
                      {query.filter_option.endDate && (
                        <Typography sx={{ fontSize: "13px" }}>
                          {dateMakerWithMonthName(query.filter_option.endDate)}
                        </Typography>
                      )}
                    </Box>
                    <Box className="content-item action-buttons">
                      <QueriesActions query={query} />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        </Box>
      }
    />
  );
};

export default RecentQueries;
