// This file deals with update current user profile

import React, { useEffect, useState, useRef } from "react";
import "./user-profile.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { ToastContainer } from "react-toastify";
import { updateCurrentUserProfile } from "../../services/services";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../Store/users-slice/users-slice";
import ProfileImageInput from "../profile-image-input";

const EditProfileForm = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);

  const initialData = {
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: ""
  };

  const { currentUser, getuserError, getUserLoading } = useSelector(
    (state) => state.usersSlice
  );

  const [unModifiedData, setUnModifiedData] = useState(initialData);
  const [dataToSend, setDataToSend] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [editProfileLoading, setEditProfileLoading] = useState(false);
  const [isFile, setIsFile] = useState(false);

  // useEffect to set form data after api call
  useEffect(() => {
    const newData = {
      firstName: currentUser.first_name || "",
      lastName: currentUser.last_name || "",
      contactNumber: currentUser.contact_number || "",
      email: currentUser.email || ""
    };
    setFormData(newData);
    setUnModifiedData(newData);
  }, [currentUser]);

  // use effect to use get current user details redux action
  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("token")]);

  // function to handle button disabled according to file change in child
  const updateFile = (data) => {
    setIsFile(data);
  };

  // useEffect to update dataToSend when formData changes
  useEffect(() => {
    const changes = {};
    for (const key in formData) {
      if (formData[key] !== unModifiedData[key]) {
        changes[key] = formData[key];
      }
    }
    setDataToSend(changes);
  }, [formData, unModifiedData]);

  useEffect(() => {
    if (Object.keys(dataToSend).length || isFile) setDisabled(false);
    else setDisabled(true);
  }, [dataToSend, isFile]);

  // Handling change in input fields to store data in formData
  const handleChange = (values) => {
    const { name, value } = values;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  // function to handle update current user details API call function
  const handleProfileUpdate = async (event) => {
    if (childRef.current) {
      event.preventDefault();
      // onImageReady function call from the child component (profile-image-input.js)
      try {
        setEditProfileLoading(true);
        await childRef.current.getImage();
      } catch (error) {
      } finally {
        setEditProfileLoading(false);
      }
    }

    event.preventDefault();
    if (!Object.keys(dataToSend).length) return;
    if (!formData.firstName && !formData.lastName && !formData.email) return;
    try {
      setEditProfileLoading(true);
      await updateCurrentUserProfile({ ...dataToSend });
      dispatch(getCurrentUser());
    } catch (error) {
    } finally {
      setEditProfileLoading(false);
    }
  };

  // JSX Section
  return (
    <Box className="user-profile-form-container slide-up">
      {/* update profile form fields */}
      <form className="profile-form">
        <h1 style={{ color: "#95e6f7" }}>Update Profile</h1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <ProfileImageInput
            url={currentUser.photo_url}
            ref={childRef}
            updateFile={(data) => updateFile(data)}
          />
        </Box>

        {getUserLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : getuserError ? (
          <Typography sx={{ color: "red", textAlign: "center" }}>
            {getuserError}
          </Typography>
        ) : (
          <div className="inputs">
            <BaseInput
              required
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={(values) => handleChange(values)}
            />
            <BaseInput
              required
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={(values) => handleChange(values)}
            />
            <BaseInput
              label="Contact Number"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={(values) => handleChange(values)}
            />
            <BaseInput
              required
              label="Email"
              name="email"
              value={formData.email}
              onChange={(values) => handleChange(values)}
            />
            <BaseButton
              onClick={handleProfileUpdate}
              type="submit"
              label="Update"
              loading={editProfileLoading}
              disabled={disabled}
            ></BaseButton>
          </div>
        )}
      </form>
      <ToastContainer />
    </Box>
  );
};

export default EditProfileForm;
