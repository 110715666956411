import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ArticleHandlingButton = ({ onClick, loading, label }) => {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Button
        onClick={onClick}
        sx={{ fontWeight: "normal", color: "#fff" }}
        style={loading ? { background: "#ffffff73", color: "#b7b7b7" } : {}}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "#4b1cf4",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px"
          }}
        />
      )}
    </Box>
  );
};

export default ArticleHandlingButton;
