import React from "react";
import OTPForm from "../../components/authentication/otp-form";

const forgotPasswordView = () => {
  return (
    <div>
      <OTPForm />
    </div>
  );
};

export default forgotPasswordView;
