import * as React from "react";
import "./run-and-save-dialog.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/system";

const RunAndSaveDialog = ({ openButton }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>{openButton}</Box>
      <Dialog open={open} onClose={handleClose}>
        <Box className="dialog-content"></Box>
        <DialogContent sx={{ padding: "15px 15px 0px 15px", width: "100%" }}>
          <DialogContentText sx={{ color: "#fff" }}>
            NLP operation
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            placeholder="Name"
            size="small"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "#d9d9d9", fontWeight: "normal" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button sx={{ color: "#d9d9d9", fontWeight: "normal" }}>
            Run & Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RunAndSaveDialog;
