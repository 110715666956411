import React, { useEffect, useRef, useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import {
  Badge,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { NotificationsOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  readNotifications
} from "../../Store/users-slice/users-slice";

export default function MenuListComposition() {
  const dispatch = useDispatch();

  const { notifications, notificationsError, notificationsLoading } =
    useSelector((state) => state.usersSlice);
  const [open, setOpen] = React.useState(false);
  const [readStatus, setReadStatus] = useState(true);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const status = notifications.some((el) => el.read_status === "unread");
    setReadStatus(status);
  }, [notifications]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const handleReadNotifications = (id) => {
    dispatch(readNotifications(id));
  };

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Tooltip title="Notification">
          <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Badge
              color="error"
              variant="dot"
              invisible={!readStatus}
              badgeContent=""
            >
              {<NotificationsOutlined sx={{ color: "#fff" }} />}
            </Badge>
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 10000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ padding: "8px", width: "300px" }}
                  >
                    {notificationsLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          padding: "10px"
                        }}
                      >
                        <CircularProgress sx={{ padding: "7px" }} />
                      </Box>
                    ) : notificationsError ? (
                      <Typography
                        sx={{
                          textAlign: "center",
                          padding: "20px 10px",
                          color: "#d51212"
                        }}
                      >
                        {notificationsError}
                      </Typography>
                    ) : (
                      <Box>
                        {notifications?.map((notification, i) => (
                          <Box
                            onClick={() =>
                              handleReadNotifications(notification.id)
                            }
                            key={i}
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              width: "100%",
                              padding: "8px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              transition: "all 0.2s ease-in-out",
                              "&:hover": { background: "#efefef" }
                            }}
                          >
                            <Typography>
                              {notification.notification_text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
