import { Box } from "@mui/material";
import "./sidebar.css";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import {
  ArticleOutlined,
  ClassOutlined,
  DomainAddOutlined,
  KeyOutlined,
  MonetizationOnOutlined,
  OtherHousesOutlined,
  Person2Outlined,
  TimelineOutlined
} from "@mui/icons-material";
import UserprofileMenu from "../users/user-profile-menu";
import { useSelector } from "react-redux";
import { toggleSidebar } from "../../Store/styles-slice/styles-slice";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../Store/users-slice/users-slice";

const SideBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const sidebarOpen = useSelector((state) => state.stylesSlice.sidebarOpen);

    // use effect to use get current user details redux action
    useEffect(() => {
      dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("token")]);

  // Funtion to handle active router link
  const isActive = (path) => {
    if (path === location.pathname) return true;
  };

  // Array which stores sidebar navigation data.
  const navItems = [
    // {
    //   path: "/",
    //   icon: <ArticleOutlined />
    // },
    {
      path: "/key",
      icon: <KeyOutlined />
    },
    {
      path: "/Person2Outlined",
      icon: <Person2Outlined />
    },
    {
      path: "",
      icon: <OtherHousesOutlined />
    },
    {
      path: "",
      icon: <ClassOutlined />
    },
    {
      path: "",
      icon: <DomainAddOutlined />
    },

    {
      path: "",
      icon: <MonetizationOnOutlined />
    },
    {
      path: "",
      icon: <TimelineOutlined />
    }
  ];

  return (
    <>
      <Box
        onClick={() => dispatch(toggleSidebar())}
        className={
          sidebarOpen ? `sidebar-background sidebar-hide` : `sidebar-background`
        }
      ></Box>
      <Box
        className={
          sidebarOpen
            ? `sidebar-container`
            : `sidebar-container sidebar-translate`
        }
        sx={{
          background: theme.palette.background.background
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            flexGrow: "1"
          }}
        >
          <Link>
            <Button
              style={
                isActive("/")
                  ? {
                      color: "#5ecee6",

                      borderLeft: "3px solid #5ecee6",
                      borderRadius: "0px"
                    }
                  : { color: "#b7b7b7", borderLeft: "3px solid #5ecee600" }
              }
            >
              <ArticleOutlined />
            </Button>
          </Link>
          {navItems.map((item, i) => (
            // <Link key={i} to={item.path}>
            <Button
              key={i}
              style={
                isActive(item.path)
                  ? {
                      color: "#5ecee6",

                      borderLeft: "3px solid #5ecee6",
                      borderRadius: "0px"
                    }
                  : { color: "#b7b7b7", borderLeft: "3px solid #5ecee600" }
              }
            >
              {item.icon}
            </Button>
            // </Link>
          ))}
        </Box>
        <UserprofileMenu />
      </Box>
    </>
  );
};

export default SideBar;
