import React from "react";
import EditProfileForm from "../../components/users/edit-profile-form";
import { LayoutProvider } from "../../components/layouts/layout-provider";
import { Box } from "@mui/system";

const EditUserProfileView = () => {
  return (
    <LayoutProvider
      child={
        <Box sx={{ padding: "20px", flexGrow: 1 }}>
          <EditProfileForm />
        </Box>
      }
    ></LayoutProvider>
  );
};

export default EditUserProfileView;
