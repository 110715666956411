import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import FilterInput from "./filter-input";
import { useDispatch, useSelector } from "react-redux";
import {
  filteredPublications,
  resetfiltersData
} from "../../../Store/publications-slice/publication-slice";

const FiltersContent = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { filtersData, searchQuery } = useSelector(
    (state) => state.publicationsSlice
  );

  const [formData, setFormData] = useState({
    date: filtersData.date || "",
    filterCriteria: filtersData.filterCriteria || "",
    endDate: filtersData.endDate || ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleResetForm = () => {
    const emptyObj = {
      filterCriteria: "",
      date: "",
      endDate: ""
    };
    setFormData(emptyObj);
    dispatch(resetfiltersData());
  };

  const handleApllyFilters = (event) => {
    if (!formData.filterCriteria && !formData.date && !formData.endDate) return;
    dispatch(
      filteredPublications({ ...formData, searchText: searchQuery, page: 1 })
    );
    handleClose(event);
  };

  return (
    <Box sx={{ display: "grid", gap: "15px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          width: "100%"
        }}
      >
        <Typography sx={{ fontSize: "13px" }}>Filter By</Typography>
        <Box
          sx={{
            flexGrow: "1",
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px"
          }}
        >
          <select
            name="filterCriteria"
            value={formData.filterCriteria}
            onChange={handleChange}
            style={{
              width: "125px",
              outline: "none",
              border: "none",
              background: "#ebebeb",
              fontSize: "12px",
              padding: "3px 5px"
            }}
          >
            <option value="bestmatch">Best match</option>
            <option value="mostrecent">Most recent</option>
            <option value="mostcited">Most cited</option>
            <option value="publicationdate">Publication date</option>
            <option value="journal">Journal</option>
          </select>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          width: "100%"
        }}
      >
        <Typography sx={{ fontSize: "13px" }}>Date</Typography>
        <Box
          sx={{
            flexGrow: "1",
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px"
          }}
        >
          <FilterInput
            name="date"
            value={formData.date}
            onChange={(event) => handleChange(event)}
            type="date"
            style={{ width: "90px" }}
          />
          <Typography sx={{ fontSize: "13px" }}>to</Typography>
          <FilterInput
            name="endDate"
            value={formData.endDate}
            onChange={(event) => handleChange(event)}
            type="date"
            style={{ width: "90px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          justifyContent: "center"
        }}
      >
        <Button
          onClick={handleResetForm}
          variant="text"
          sx={{
            background: "#ebebeb",
            fontSize: "13px",
            borderRadius: "2px",
            padding: "4px 22px 4px 22px",
            color: "#505050"
          }}
        >
          Reset
        </Button>
        <Button
          onClick={handleApllyFilters}
          sx={{
            background: "#e1e0e0",
            fontSize: "13px",
            borderRadius: "2px",
            padding: "4px 22px 4px 22px",
            color: "#505050"
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default FiltersContent;
