// This file deals with the user signup/create account

import React, { useState, useEffect } from "react";
import "./authentication.css";
import { Link } from "react-router-dom";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ReCAPTCHA from "react-google-recaptcha";
import { signup } from "../../services/services";
import { useNavigate } from "react-router-dom";
import PasswordValidator from "../password-validator"

const SignupForm = () => {
  // State variables
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "", // Added contact number
    password: "",
    confirmPassword: "",
    invitationCode: "" // Add invitationCode to formData
  });
  const [captcha, setCaptcha] = useState("");
  const [captchaEmpty, setCaptchaEmpty] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
  }, []);

  // Form data change handler
  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  // Signup submit handler
  const handleSignup = async (event) => {
    event.preventDefault();

    for (const key in formData) {
      if (!formData[key]) return;
    }

    if (!captcha) {
      setCaptchaEmpty(true);
      return;
    }

    try {
      setSignupLoading(true);
      await signup(formData); // Send invitation code to backend
      navigate("/login");
    } catch (error) {
      // Handle signup errors, including invalid invitation code error
      console.error(error); // Log the error for debugging
      // You might want to display a more user-friendly error message here
    } finally {
      setSignupLoading(false);
    }
  };

  // reCaptcha check
  function onChange(value) {
    setCaptcha(value);
    if (value) setCaptchaEmpty(false);
  }

  // JSX
  return (
    <div
      style={{
        background: theme.palette.background.background
      }}
      className="auth-form-container"
    >
      <form className="auth-form">
        <h1 style={{ color: "#95e6f7" }}>Create Account</h1>
        <div className="inputs">
          {/* ... other input fields ... */}

          <BaseInput
            required
            onChange={(values) => handleChange(values)}
            name="invitationCode"
            label="Invitation Code"
          />

          {/* ... other input fields ... */}
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SignupForm;
