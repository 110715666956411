import React from "react";
import ChangePasswordForm from "../../components/users/change-password-form";
import { Box } from "@mui/material";

const ChangePasswordView = () => {
  return (
    <Box sx={{ height: "100%", padding: "20px" }}>
      <ChangePasswordForm />
    </Box>
  );
};

export default ChangePasswordView;
