import React from "react";
import "./filters.css";

const FilterInput = ({
  type,
  placeholder,
  disabled,
  style,
  value,
  name,
  onChange
}) => {
  const handleChange = (event) => {
    onChange(event);
  };
  return (
    <input
      autoComplete="off"
      value={value}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
      onChange={handleChange}
      name={name}
      className="filter_input"
    />
  );
};

export default FilterInput;
