// Base input component enhances code reusability,
// making it swift to design and control through prop passing.

import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import { InputBase, styled, alpha } from "@mui/material/";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  position: "relative",
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: 16,
    borderRadius: "35px",
    padding: "10px 12px",
    border: "1px solid grey",
    color: "white",
    width: "100%",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const BaseInput = ({
  label,
  name,
  onChange,
  required,
  sx,
  type,
  disabled,
  value,
  readOnly
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState(false);

  // this function handle the change in base-input to send value to parent
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  // this useEffect is to handle field type by detecting label
  useEffect(() => {
    if (type === "password") setPasswordField(true);
    else setPasswordField(false);
  }, [type]);

  // this funtion controle the type of password field to text/password
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <FormControl size="small" variant="outlined" sx={{ ...sx }}>
      {/* There are two field accociated with base-input, if label of field will be password or confirm password then
          first field will be show, otherwise second field will be show */}
      {passwordField ? (
        <BootstrapInput
          required={required}
          sx={{
            "& label": {
              "&.Mui-focused": {
                color: "#fff"
              }
            }
          }}
          onChange={handleChange}
          value={value}
          name={name}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{ position: "absolute", right: "17px" }}
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff sx={{ color: "#fff" }} />
                ) : (
                  <Visibility sx={{ color: "#fff" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          placeholder={label}
        />
      ) : (
        <BootstrapInput
          autoComplete="off"
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          type={type}
          value={value}
          onChange={handleChange}
          name={name}
          sx={{
            "&:focus .MuiOutlinedInput-root": {
              border: "1px solid rgba(255, 255, 255, 0.51)"
            }
          }}
          placeholder={label}
        />
      )}
    </FormControl>
  );
};

export default BaseInput;
