// Services hub to controll all the third party APIs

import { toast } from "react-toastify";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// -------------------[ function to handle user login API call ]-------------------
export function login(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/user/login`;
      const response = await axios.post(apiUrl, params, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      localStorage.setItem("token", response.data.data.token);
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle login with google ]-------------------
export function loginWithGoogle(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/auth/google/login`;
      const response = await axios.post(
        apiUrl,
        { idToken: token },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      localStorage.setItem("token", response.data.data);
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle login with Facebook ]-------------------
export function loginWithFacebook(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/auth/facebook/login`;
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      localStorage.setItem("token", response.data.data);
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle user signup API call ]-------------------
export async function signup(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/user/signup`;
      const response = await axios.post(apiUrl, params, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle user forgot password API call ]-------------------
export async function forgetPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/user/forget-password`;
      const response = await axios.post(
        apiUrl,
        { email },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle user send one time password (OTP) API call ]-------------------
export async function verifyOtpCode(otp) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/user/verify-code`;
      const response = await axios.post(
        apiUrl,
        { verificationCode: otp },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle reset password API call ]-------------------
export async function resetPassword(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/user/reset-password`;
      const response = await axios.post(apiUrl, params, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle user Upate current user details API call ]-------------------
export async function updateCurrentUserProfile(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/user/update-profile`;
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "x-access-token": token
        }
      });
      toast.success("Profile Updated Successfully");
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle change password API call ]-------------------
export async function changePassword(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/user/change-password`;
      const response = await axios.post(apiUrl, params, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      toast.success(response.data.data);
      resolve(response);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle publications search API call ]-------------------
export async function searchPublications(searchText, page) {
  return new Promise(async (resolve, reject) => {
    let pageNumber = 1;
    if (page) pageNumber = page;
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      const apiUrl = `${BASE_URL}/api/publication/?q=${searchText}&offset=${pageNumber}`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      const data = response.data.data;
      resolve({
        data: data.publications,
        totalPublications: data.totalPublications,
        error: response.data.data.error
      });
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

// -------------------[ function to handle Filter publications API call ]-------------------
export async function getFilteredPublications(payload) {
  console.log(payload);
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      let queries = "";
      Object.entries(payload).forEach(([key, value]) => {
        if (value && key !== "page") {
          queries += `&${key}=${value}`;
        }
      });
      const apiUrl = `${BASE_URL}/api/publication/?offset=${payload.page}${queries}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      const data = response.data.data;
      resolve({
        data: data.publications,
        totalPublications: data.totalPublications,
        error: response.data.data.error
      });
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

// -------------------[ function to handle download article API call ]-------------------
export async function downloadArticle(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const apiUrl = `${BASE_URL}/api/publication/download-pdf/${id}`;

      const response = await axios.get(apiUrl, {
        responseType: "arraybuffer"
      });
      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      const title = `publication_${day}-${month}-${year}, ${hours}_${minutes}`;

      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve({
        data: response.data
      });
    } catch (error) {
      toast.error("Something went wrong!");
      reject(error);
    }
  });
}
// -------------------[ function to handle download selected article API call ]-------------------
export async function downloadAllSectedArticle(publicationIds) {
  return new Promise(async (resolve, reject) => {
    try {
      // Append publicationIds to the URL
      const apiUrl = `${BASE_URL}/api/publication/download-multiple-pdfs`;

      const response = await axios.post(
        apiUrl,
        { publicationIds },
        {
          responseType: "blob"
        }
      );

      const blob = new Blob([response.data]);

      // Create a link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      const title = `publication_${day}-${month}-${year}, ${hours}_${minutes}`;

      link.download = `${title}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve({
        data: response
      });
    } catch (error) {
      toast.error("Something went wrong!");
      reject(error);
    }
  });
}

// -------------------[ function to handle save article API call ]-------------------
export async function saveArticle(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/publication/save-pdf/${id}`;
      const response = await axios.post(apiUrl, null, {
        headers: {
          "x-access-token": token
        }
      });
      toast.success(response.data.data);
      resolve({
        data: response
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle save all selected article API call ]-------------------
export async function saveSearchQuery(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/query`;
      const response = await axios.post(apiUrl, data, {
        headers: {
          "x-access-token": token
        }
      });

      toast.success("Query saved successfully");
      resolve({
        data: response
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

//  -------------------[ function to handle get recent search queries API call ]-------------------
export async function fetchRecentQueries() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/query`;

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token
        }
      });
      const data = response.data.data;
      resolve(data);
    } catch (error) {
      if (error.response) {
        reject(error.response.data.data.error);
      } else if (error.message) {
        reject(error.message);
      } else {
        reject("Network Problem");
      }
    }
  });
}

// -------------------[ function to handle share search queries API call ]-------------------
export async function shareQuery(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/query/share`;
      const response = await axios.post(apiUrl, data, {
        headers: {
          "x-access-token": token
        }
      });
      toast.success(response.data.data);
      resolve({
        data: response
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}

// -------------------[ function to handle delete search query API call ]-------------------
export async function deleteQuery(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `${BASE_URL}/api/query/${id}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          "x-access-token": token
        }
      });
      toast.success(response.data.data);
      resolve({
        data: response
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.data.error);
        reject(error.response.data.data.error);
      } else if (error.message) {
        toast.error(error.message);
        reject(error.message);
      } else {
        toast.error("Network Problem");
        reject(error);
      }
    }
  });
}
