// useStylesSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const useStylesSlice = createSlice({
  name: "styles",
  initialState: {
    sidebarOpen: true
  },
  reducers: {
    setToggleSidebar: (state, action) => {
      state.sidebarOpen = !state.sidebarOpen;
    }
  }
});

export default useStylesSlice.reducer;

export const { setToggleSidebar } = useStylesSlice.actions;

export const toggleSidebar = () => async (dispatch) => {
  dispatch(setToggleSidebar());
};
