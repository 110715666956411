// usersSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  getCurrentUserDetails,
  fetchAllUsersLIst,
  fetchNotifications,
  callReadNotifications
} from "../../services/users-service";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    currentUser: {},
    usersList: [],
    notifications: [],
    getUsersListError: "",
    getuserError: "",
    notificationsError: "",
    notificationsLoading: false,
    usersListLoading: false,
    getUserLoading: true
  },
  reducers: {
    // reducer to set current user details to state
    setCurrentUser: (state, { payload }) => {
      state.getuserError = "";
      state.getUserLoading = false;
      if (payload.data) state.currentUser = payload.data;
      if (payload.error) state.getuserError = payload.error;
    },
    // reducer to set all users details list to state
    setUsersList: (state, { payload }) => {
      state.getUsersListError = "";
      if (payload.data) state.usersList = payload.data;
      if (payload.error) state.getUsersListError = payload.error;
    },
    setUsersListLoading: (state, { payload }) => {
      state.getUserLoading = payload;
    },
    setNotifications: (state, { payload }) => {
      state.notificationsError = "";
      if (payload.data) state.notifications = payload.data;
      if (payload.error) state.notificationsError = payload.error;
    },
    setNotificationsLoading: (state, { payload }) => {
      state.notificationsLoading = payload;
    },
    setNotificationRead: (state, { payload }) => {
      const notificationToRead = state.notifications.find(
        (notification) => notification.id === payload
      );

      if (notificationToRead) {
        notificationToRead.read_status = "read";
      }
    }
  }
});

export default usersSlice.reducer;

export const {
  setCurrentUser,
  setUsersList,
  setUsersListLoading,
  setNotifications,
  setNotificationsLoading,
  setNotificationRead
} = usersSlice.actions;

// Get current user details
export const getCurrentUser = () => async (dispatch) => {
  try {
    const data = await getCurrentUserDetails();
    dispatch(setCurrentUser({ data }));
  } catch (error) {
    dispatch(setCurrentUser({ error }));
  }
};

// Get all users details list
export const getAllUsersLIst = () => async (dispatch) => {
  try {
    dispatch(setUsersListLoading(true));
    const data = await fetchAllUsersLIst();
    dispatch(setUsersList({ data }));
  } catch (error) {
    dispatch(setUsersList({ error }));
  } finally {
    dispatch(setUsersListLoading(false));
  }
};

// Get Notification
export const getNotifications = () => async (dispatch) => {
  try {
    dispatch(setNotificationsLoading(true));
    const response = await fetchNotifications();
    if (response?.error) dispatch(setNotifications({ error: response.error }));
    else dispatch(setNotifications({ data: response }));
  } catch (error) {
    dispatch(setNotifications({ error }));
  } finally {
    dispatch(setNotificationsLoading(false));
  }
};

// Get Notification
export const readNotifications = (id) => async (dispatch) => {
  try {
    dispatch(setNotificationRead(id));
    await callReadNotifications(id);
  } catch (error) {
    dispatch(setNotifications({ error }));
  }
};
