import React, { useState } from "react";
import "./publications-right-panel.css";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import RunAndSaveDialog from "./run-and-save-dialog";

export const PublicationsRightPanel = () => {
  const { selectedPublications } = useSelector(
    (state) => state.publicationsSlice
  );

  const [panelOpen, setpanelOpen] = useState(true);
  return (
    <Box
      className={panelOpen ? `right-panel` : "right-panel translate-panel"}
      sx={{
        opacity: selectedPublications?.length > 0 ? 1 : 0,
        transition: "all 0.2s ease-in-out",
        pointerEvents: selectedPublications?.length ? `all` : `none`
      }}
    >
      <Box className={`panel-content`}>
        <IconButton
          onClick={() => setpanelOpen(!panelOpen)}
          className="toggle-panel-button"
        >
          {panelOpen ? (
            <KeyboardDoubleArrowRight />
          ) : (
            <KeyboardDoubleArrowLeft />
          )}
        </IconButton>
        <Typography sx={{ fontSize: "19px", color: "#d9d9d9" }}>
          Natural Language Processing
        </Typography>
        <RunAndSaveDialog
          openButton={
            <Button size="small" className="panel-button">
              Latent Topic Identification
            </Button>
          }
        />
        <RunAndSaveDialog
          openButton={
            <Button size="small" className="panel-button">
              Entity Recognition
            </Button>
          }
        />
        <RunAndSaveDialog
          openButton={
            <Button size="small" className="panel-button">
              Multi-Publication Summarization
            </Button>
          }
        />
      </Box>
    </Box>
  );
};
