// Base button component enhances code reusability,
// making it swift to design and control through prop passing.

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const BaseButton = ({ label, onClick, type, sx, disabled, loading }) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Button
            disabled={disabled}
            type={type}
            onClick={onClick}
            variant="contained"
            sx={{
              ...sx,
              borderRadius: 5,
              width: "100%"
            }}
            style={
              loading ? { color: "#a5a5a5", backgroundColor: "#1a255f" } : {}
            }
          >
            <Typography>{label}</Typography>
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px"
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default BaseButton;
