import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../Store/auth-slice/index";
import usersSlice from "../Store/users-slice/users-slice";
import stylesSlice from "../Store/styles-slice/styles-slice";
import publicationsSlice from "./publications-slice/publication-slice";

export const store = configureStore({
  reducer: {
    authSlice: authSlice,
    usersSlice: usersSlice,
    stylesSlice: stylesSlice,
    publicationsSlice: publicationsSlice
  }
});
