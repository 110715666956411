// Authentication slice to controle state related user authentications

import { createSlice } from "@reduxjs/toolkit";
import { login, signup } from "../../services/services";

export const AuthSlice = createSlice({
  name: "counter",
  initialState: "Redux State",
  reducers: {
    // action to controle user login funtion
    loginAction: (state, action) => {
      login(action.payload);
      return state;
    },

    // action to controle user signup funtion
    signupAction: (state, action) => {
      signup(action.payload);
      return state;
    },
  },
});

export default AuthSlice.reducer;

export const { loginAction, signupAction } = AuthSlice.actions;
