// This file deals with user change password

import React, { useState } from "react";
import "./user-profile.css";
import { Box } from "@mui/material";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { ToastContainer } from "react-toastify";
import { changePassword } from "../../services/services";

const ChangePasswordForm = () => {
  const initialFormData = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };
  const [formData, setFormData] = useState(initialFormData);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  // Handling change in input fields to store data in formData
  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  // function to handle change password API call function
  const handleLChangePassword = async (event) => {
    for (const key in formData) {
      if (!formData[key]) return;
    }
    event.preventDefault();
    try {
      setChangePasswordLoading(true);
      await changePassword(formData);
      setFormData(initialFormData);
    } catch (error) {
    } finally {
      setChangePasswordLoading(false);
    }
  };

  // JSX Section
  return (
    <Box className="user-profile-form-container slide-up">
      {/* change password form fields */}
      <form className="profile-form">
        <h1 style={{ color: "#95e6f7" }}>Change Password</h1>
        <div className="inputs">
          <BaseInput
            required
            label="Old Password"
            type="password"
            name="oldPassword"
            value={formData.oldPassword}
            onChange={(values) => handleChange(values)}
          />
          <BaseInput
            required
            label="New Password"
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={(values) => handleChange(values)}
          />
          <BaseInput
            required
            label="Confirm New Password"
            type="password"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            onChange={(values) => handleChange(values)}
          />
          <BaseButton
            type="submit"
            label="Change"
            loading={changePasswordLoading}
            onClick={handleLChangePassword}
          ></BaseButton>
        </div>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default ChangePasswordForm;
