// This file contains all the color variables

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primary: {
    main: "#473df5",
  },
  background: {
    gradiant: "background: rgb(10,15,45)",
    background:
      "linear-gradient(0deg, rgba(10,15,45,1) 0%, rgba(27,18,84,1) 35%, rgba(37,16,78,1) 100%)",
    background2:
      "linear-gradient(90deg, rgba(10,15,45,1) 0%, rgba(27,18,84,1) 35%, rgba(37,16,78,1) 100%)",
  },
};
