import React from "react";
import { Box } from "@mui/material";
import ResetPasswordForm from "../../components/authentication/reset-password-form";

const ResetPasswordView = () => {
  return (
    <Box>
      <ResetPasswordForm />
    </Box>
  );
};

export default ResetPasswordView;
