// This file deals with otp verification

import React, { useState, useEffect } from "react";
import "./authentication.css";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import BaseButton from "../base-button";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { verifyOtpCode } from "../../services/services";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";

const OTPForm = () => {
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  const handleChange = (value) => {
    setOtp(value);
  };

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
  });

  // function to handle login API call function
  const verify = async (event) => {
    if (!otp) return;
    if (event) event.preventDefault();
    try {
      setOtpLoading(true);
      const response = await verifyOtpCode(otp);
      localStorage.setItem("reset_email", response.data.data);
      navigate("/reset-password");
    } catch (error) {
    } finally {
      setOtpLoading(false);
    }
  };

  useEffect(() => {
    if (otp.length === 6) verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  // JSX Section
  return (
    <Box
      className="auth-form-container"
      sx={{
        background: theme.palette.background.background
      }}
    >
      {/* otp form form fields */}
      <form className="auth-form">
        <h1 style={{ color: "#95e6f7" }}>Reset Password</h1>
        <div className="inputs">
          <MuiOtpInput
            autoFocus
            length={6}
            value={otp}
            onChange={handleChange}
          />
          <BaseButton
            loading={otpLoading}
            onClick={verify}
            type="submit"
            label="Verify"
          ></BaseButton>

          <Link
            to="/forgot-password"
            className="navigation-link"
            style={{ margin: "auto", width: "fit-content" }}
          >
            <Typography>Resend Code</Typography>
          </Link>
        </div>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default OTPForm;
