// This file deals with the reset reset password

import React, { useState, useEffect } from "react";
import "./authentication.css";
import { Link } from "react-router-dom";
import BaseInput from "../base-input";
import BaseButton from "../base-button";
import { useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { resetPassword } from "../../services/services";
import { useNavigate } from "react-router-dom";
import PasswordValidator from "../password-validator";

const ResetPasswordForm = () => {
  // state to store signup form data
  const [formData, setFormData] = useState({
    email: localStorage.getItem("reset_email"),
    newPassword: "",
    confirmPassword: ""
  });
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/");
  });

  // Handling change in input fields to store data in formData
  const handleChange = ({ name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  // function to handle Reset password API call function
  const handleResetPassword = async (event) => {
    for (const key in formData) {
      if (!formData[key]) return;
    }
    event.preventDefault();
    try {
      setResetPasswordLoading(true);
      await resetPassword(formData);
      navigate("/login");
    } catch (error) {
    } finally {
      setResetPasswordLoading(false);
    }
  };

  // JSX Section
  return (
    <div
      style={{
        background: theme.palette.background.background
      }}
      className="auth-form-container"
    >
      {/* reset password form fields */}
      <form className="auth-form">
        <h1 style={{ color: "#95e6f7" }}>Reset Password</h1>
        <div className="inputs">
          <BaseInput
            readOnly
            required
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={(values) => handleChange(values)}
          />
          <BaseInput
            required
            label="New Password"
            name="newPassword"
            type="password"
            onChange={(values) => handleChange(values)}
          />
          <BaseInput
            required
            label="Confirm New Password"
            name="confirmPassword"
            type="password"
            onChange={(values) => handleChange(values)}
          />
          <PasswordValidator
            data={{
              password: formData.newPassword,
              confirmPassword: formData.confirmPassword
            }}
          />
          <BaseButton
            onClick={handleResetPassword}
            loading={resetPasswordLoading}
            type="submit"
            label="Reset"
          />
          <Box sx={{ color: "#FFF", display: "flex", margin: "auto" }}>
            <Link to="/forgot-password" className="navigation-link">
              <Typography>Resend Code</Typography>
            </Link>
          </Box>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ResetPasswordForm;
