// This file contains all the variables related typegraphy (size, font weight etc)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  h1: {
    fontSize: "2.5rem",
    fontWeight: 600,
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 500,
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: "1rem",
  },
  subtitle2: {
    fontSize: "0.875rem",
  },
  overline: {},
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: "0.875rem",
  },
  button: {
    fontSize: "1rem",
    fontWeight: 600,
    textTransform: "none",
  },
};
