import React from "react";
import { useNavigate } from "react-router-dom";

const TokenExpiration = () => {
  const navigate = useNavigate();

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      // eslint-disable-next-line no-unused-vars
      const [header, payload] = token.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const expirationTime = decodedPayload.exp;
      const expirationDate = new Date(expirationTime * 1000);
      const currentDate = new Date();
      if (!token) return;
      if (currentDate > expirationDate) {
        localStorage.clear("token");
        navigate("/login");
        window.location.reload();
      }
    }
  };
  setTimeout(() => {
    checkTokenExpiration();
  }, 3000);

  return <div></div>;
};

export default TokenExpiration;
