// This is the initial structure of default layout (which includes, header, sidebar etc)

import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../header/header";
import SideBar from "../sidebar/sidebar";
import "./default-layout.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TokenExpiration from "../token-expiration/token-expiration";

const DefaultLayout = ({ children }) => {
  const navigate = useNavigate();
  // useEffect to monniter user authentication, if user is not logged in, then redirect to login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) navigate("/login");
  });

  return (
    <Box className="scroll-box-container">
      <SideBar />
      <Box className="right-content">
        <Header />
        <>{children || <Outlet />}</>
      </Box>
      <TokenExpiration />
    </Box>
  );
};

export default DefaultLayout;
